import React, { useState, createRef, useEffect } from "react"
import { Button, Modal, Form } from "react-bootstrap"

import { CheckReportAuthToken, SaveReportAuthToken } from "../../../siteUtils/reportHelpers"

interface LoginHandlerProps {
  children:any
}
function LoginHandler({ children }:LoginHandlerProps):JSX.Element {
  const [isAuthenticated,setIsAuthenticated] = useState<boolean>(false),
        [showModal,setShowModal] = useState<boolean>(true),
        [formIsLoading,setFormIsLoading] = useState<boolean>(false),
        [errorMessage,setErrorMessage] = useState<string>(""),
        passwordRef = createRef<HTMLInputElement>()

  function handleHideModal() {
    setShowModal(false)
  }

  function handleSubmitClick(event:React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    submitForm()
  }
  function handleFormSubmit(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    submitForm()
  }

  function submitForm() {
    const pwd = passwordRef.current?.value || ""
    setFormIsLoading(true)
    setErrorMessage("")
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/reports-auth`, {
      method: "POST",
      body: JSON.stringify({password: pwd})
    }).then((response)=>{
      setFormIsLoading(false)
      if(response.ok && response.status === 200) {
        SaveReportAuthToken()
        setIsAuthenticated(true)
      } else {
        setErrorMessage("Invalid password")
      }
    }).catch((err)=>{
      console.error(err)
      setFormIsLoading(false)
    })
  }

  useEffect(()=>{
    if(CheckReportAuthToken()) {
      setIsAuthenticated(true)
      setFormIsLoading(false)
      setErrorMessage("")
    }
  }, [])

  return (
    <div>
      { isAuthenticated ? (
        <div>
          {children}
        </div>
      ) : (
        <div>
          <Modal
            show={showModal}
            backdrop="static" >
            <Modal.Header>
              To view reports please login.
            </Modal.Header>
            <Modal.Body>
              {errorMessage ? (<p className="bg-danger text-white p-2">{errorMessage}</p>) : null}
              <Form onSubmit={handleFormSubmit}>
                <Form.Group className="mb-3" controlId="authForm.password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="***" ref={passwordRef} disabled={formIsLoading} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit" onClick={handleSubmitClick} disabled={formIsLoading}>Login</Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  )
}

export default LoginHandler