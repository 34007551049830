import React, { useEffect, useState } from "react"
import { Form, Table } from "react-bootstrap"


interface FeedbackRowData {
  url: string,
  story_name: string,
  story_uuid: string,
  story_slug: string,
  positiveResponses: number,
  negativeAccurate: number,
  negativeClear: number,
  negativeRelevant: number
}
function FeedbackReportTable():JSX.Element {
  const [feedbackData, setFeedbackData] = useState<Array<FeedbackSimple>>([]),
        [rowData, setRowData] = useState<Array<FeedbackRowData>>([]),
        [feedbackFilter, setFeedbackFilter] = useState<number>(0)

  useEffect(()=>{
    LoadData()
  }, [])

  function LoadData() {
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/feedback-simple`, {
      method: "GET"
    })
    .then((response)=>{
      if(response.ok && response.status === 200) {
        response
        .text()
        .then((rawData:string)=>{
          let data = JSON.parse(rawData)
          setFeedbackData(data.data)
        })
        .catch((err)=>{
          console.error(err)
        })
      } else { }
    })
    .catch((err)=>{
      console.error(err)
    })
  }

  useEffect(()=>{
    if(feedbackData && feedbackData.length > 0) {
      let newRows:Array<FeedbackRowData> = []

      let filterDate = SubtractMonths(feedbackFilter || 0)
      
      feedbackData.filter((fb)=>{
        if(feedbackFilter && fb._ts) {
          let ts = new Date(fb._ts)
          if(ts.getTime() > filterDate.getTime()) {
            return true
          } else {
            return false
          }
        }

        return true
      }).forEach((feedback)=>{
        
        
        let exists = newRows.find((r)=>{return r.url === feedback.url})

        if(!exists) {
          newRows.push({
            url: feedback.url,
            positiveResponses: 0,
            negativeAccurate: 0,
            negativeClear: 0,
            negativeRelevant: 0,
            story_name: feedback.story_name || "",
            story_uuid: feedback.story_uuid || "",
            story_slug: feedback.story_slug || ""
          })
        }

        newRows = newRows.map((d)=>{
          if(d.url === feedback.url) {
            let newD = {
              ...d
            }
            if(feedback.helpful) {
              newD.positiveResponses = newD.positiveResponses + 1
            }
            if(feedback.reason === "It wasn't accurate") {
              newD.negativeAccurate = newD.negativeAccurate + 1
            } else if(feedback.reason === "It wasn't clear") {
              newD.negativeClear = newD.negativeClear + 1
            } else if(feedback.reason === "It wasn't relevant") {
              newD.negativeRelevant = newD.negativeRelevant + 1
            }
            return newD
          }
          return d
        })
      })
      
      setRowData(newRows)
    }
  }, [feedbackData, feedbackFilter])

  function handleFilterChanged(event:React.ChangeEvent<HTMLSelectElement>) {
    event.preventDefault()
    setFeedbackFilter(parseInt(event.target.value || "0") || 0)
  }

  return (
    <div>
      <div className="container">
        <br/>
        <div className="d-flex justify-content-end">
          <div style={{width: 200}}>
            <Form.Select onChange={handleFilterChanged} value={feedbackFilter}>
              <option value="0">All</option>
              <option value="1">Last Month</option>
              <option value="3">Last 3 Months</option>
              <option value="12">Last Year</option>
            </Form.Select>
          </div>
        </div>
        <br/>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Positive</th>
              <th>Negative Accuracy</th>
              <th>Negative Clear</th>
              <th>Negative Relevant</th>
            </tr>
          </thead>
          <tbody>
            {rowData.map((row)=>(
              <tr key={row.url}>
                <td style={{maxWidth: 250, overflow: 'hidden'}}>{row.story_name || row.url}</td>
                <td>{row.positiveResponses}</td>
                <td>{row.negativeAccurate}</td>
                <td>{row.negativeClear}</td>
                <td>{row.negativeRelevant}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

function SubtractMonths(numMonths:number, date = new Date()) {
  const dateCopy = new Date(date.getTime());
   
  dateCopy.setMonth(dateCopy.getMonth() - numMonths)

  return dateCopy
}

export default FeedbackReportTable