function SaveReportAuthToken() {
  if(window && window.localStorage) {
    let expDate = Date.now()

    // 15 minute authentication limit
    expDate += ((15*60)*1000)
    window.localStorage.setItem('iqs-reports-auth-token', `${expDate}`)
  }
}

function CheckReportAuthToken():boolean {
  if(window && window.localStorage) {
    let raw = parseInt(window.localStorage.getItem('iqs-reports-auth-token') || "0")
    if(raw) {
      let now = Date.now()
      if(now < raw) {
        return true
      }
    }
  }
  return false
}

export { SaveReportAuthToken, CheckReportAuthToken }