import React from "react"
import { Link } from "gatsby"


function ReportNavbar():JSX.Element {

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <span className="navbar-brand mb-0 h1">Reports</span>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-end" style={{width: "100%"}}>
            <li className="nav-item">
              <Link to="/reports/feedback" className="nav-link" activeClassName="active">Feedback</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default ReportNavbar