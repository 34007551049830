import React from "react"
import { Helmet } from "react-helmet"

import GlobalLayout from "../../components/layout/GlobalLayout"
import ReportNavbar from "../../components/reports/reportNavbar/ReportNavbar"

import LoginHandler from "../../components/internal/loginHandler/LoginHandler"
import FeedbackReportTable from "../../components/reports/feedbackReportTable/FeedbackReportTable"

function FeedbackReport() {  
  
  return (
    <GlobalLayout>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
        <meta name="robots" content="nosnippet" />
        <meta name="robots" content="noarchive" />
        <meta name="robots" content="notranslate" />
      </Helmet>
      <div>
        <LoginHandler>
          <ReportNavbar />
          <FeedbackReportTable />
        </LoginHandler>
      </div>
    </GlobalLayout>
  )
}

export default FeedbackReport